<div class="component-import-multi-image">
    <ng-container *ngIf="listImageHandler.length > 0">
        <div class="image-container">
            <div *ngFor="let image of listImageHandler">
                <div class="image">
                    <i (click)="deleteImageFromList(image)" class="fa-solid fa-circle-xmark icon-delete"></i>
                    <img [src]="image.ImgUrl" alt="">
                </div>
                <div class="checkbox">
                    <div class="circle" (click)="makeThumbnail(image)" title="Làm đại diện">
                        <ng-container *ngIf="image.IsThumbnail">
                            <div class="circular">
                                <i class="fa-solid fa-check"></i>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="listImageHandler.length < 10">
        <kendo-fileselect class="file-select" [restrictions]="fileRestrictions" ImportMultiImageDirective
            (select)="onFileSelected($event)" FileSelected>
        </kendo-fileselect>
    </ng-container>
</div>