<div class="backgroundPopUp">
    <div class="PopUp" [ngStyle]="{'width.px': widthPopUp, 'height.px': heightPopUp}">
        <div class="headerPopUp" [style.height.px]="heightHeader">
            <div class="contentHeader" [style.gap.px]="gap">
                <i class="fa-solid fa-triangle-exclamation" [ngStyle]="{
                    'font-size.px':sizeIcon,
                    'color': color,
                }"></i>
                <span class="textHeader" [ngStyle]="{
                    'color':color,
                    'font-size.px':fontSizeHeader,
                    'font-weight.px':fontWeightHeader,
                }">{{textHeader}}</span>
            </div>
        </div>
        
        <div class="contentPopUp">
            <div #contentPopupCheck>
                <ng-content select=".content-popup"></ng-content>
            </div>
            <ng-container *ngIf="contentPopupCheck.innerHTML.trim().length === 0">
                <div class="contentDefault">
                    <div class="contentQuestion">Xác nhận thay đổi trạng thái đơn hàng?</div>
                    <div class="contentWarning">Đơn hàng sẽ không thể quay về trạng thái cũ</div>
                    <ng-container *ngIf="value == 3 || value == 9 || value == 13 || value == 14 || value == 15 || value == 20 || value == 21">
                        <component-text-area [fontsize]="15" [height]="70" [width]="270" [widthBox]="320" [label]="'Lý do:'" (valueTextArea)="sendReasonText($event)"></component-text-area>
                    </ng-container>


                </div>
            </ng-container>
        </div>
        
        <div class="footerPopUp">
            <div #footerPopUpCheck>
                <ng-content select=".button-popup"></ng-content>
            </div>
            <ng-container *ngIf="footerPopUpCheck.innerHTML.trim().length === 0">
                <div class="footerDefault">
                    <div class="buttonNoChange">
                        <component-button [text]="'Không đổi'" [classIconFontAwesome]="''" [width]="144"  [height]="58" [rounded]="0" 
                    [bgColor]="'#FFFFFF'" [color]="'#959DB3'" [size]="16" [value]="value"></component-button>
                    </div>
                    <div class="buttonAccept">
                        <component-button [text]="text" [classIconFontAwesome]="classIconFontAwesome" [width]="224" [height]="58" [rounded]="0" 
                        [bgColor]="bgColor" [color]="'#FFFFFF'" [size]="16" (sendValue)="onClickButtonUp($event)" [value]="value"></component-button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

