export const DataError = [
    {id: 0, text: "Incorrect color", select: false},
    {id: 1, text: "Incorrect size", select: false},
    {id: 2, text: "Damaged item", select: false},
    {id: 3, text: "Missing items", select: false},
    {id: 5, text: "Wrong item delivered", select: false},
    {id: 6, text: "Package lost", select: false},
    {id: 7, text: "Poor packaging", select: false},
    {id: 8, text: "Incomplete order", select: false},  
];
