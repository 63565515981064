<app-loading [isLoading]="isLoading"></app-loading>

<div class="main">
    <div class="content">
        <div *ngIf="type == 1" class="form-thanks">
            <div class="card">
                <i class="fa-solid fa-check"></i>
                 <p class="cookieHeading">Succeeded!</p>
                 <p class="cookieDescription">please check your mail to comfirm forget password <br><a href="#">Read cookies policies</a>.</p>
               
                 <div class="buttonContainer">
                   <button (click)="handleNavigate('account/login')" class="acceptButton">Back to login</button>
                 </div>
                 
               
               </div>
               
               
        </div>

        <div *ngIf="type == 0" class="form">
            <div class="info">
                <div>
                    <div class="title">
                        Password retrieval
                    </div>
                    <div class="input-area">
                        <div class="input">
                            <i  class="fa-solid fa-user"></i>
                            <input [(ngModel)]="username" placeholder="Enter mail or number phone"/>
                        </div>
                    </div>

                    <div (click)="handleSendUser()"  class="btnlogin">
                        {{'send' | uppercase}}
                    </div>
                    <div (click)="handleNavigate('account/login')"  class="btnBacklogin">
                        {{'back to login' | uppercase}}
                    </div>
                </div>
            </div>
            <div class="image">
                <img src="https://img.freepik.com/premium-vector/forgot-password-illustration_65141-418.jpg" alt="">
            </div>
        </div>

        <div *ngIf="type == 2" class="form">
            <div class="image">
                <img src="https://www.wellandgood.com/wp-content/uploads/2023/11/GettyImages-1307717764.jpg" alt="">
            </div>
            <div  class="info">
                <div>
                    <div class="title">
                        Change password
                    </div>
                    <div >
                        <div class="input-area">
                            <div class="row">
                                <div [ariaDisabled]="true" class="input">
                                    <i class="fa-solid fa-user"></i>
                                    <input  [disabled]="true" [(ngModel)]="this.username"/>
                                </div>
                                <div class="input">
                                    <i class="fa-solid fa-lock"></i>
                                    <input  [(ngModel)]="password"  type="{{showPassword}}" placeholder="Password"/>
                                    <i (click)="handleChangeShowPassword()" class="fa-regular fa-eye"></i>
                                </div>
                                <div class="input">
                                    <i class="fa-solid fa-lock"></i>
                                    <input [(ngModel)]="repeatPassword" type="{{showRePassword}}" placeholder="Repeat Password"/>
                                    <i (click)="handleChangeShowrRePassword()" class="fa-regular fa-eye"></i>
                                </div>
                               
                            </div>
                           
                            

                            
                            
                            
                            <div (click)="handleNavigate('account/login')" class="back">
                                Back to login
                            </div>
                           
                        </div>
                        <div (click)="handleChangePassword()" class="btnlogin">
                            {{'Change' | uppercase}}
                        </div>
                    </div>
                    

                </div>
            </div>
            
        </div>
    </div>
</div>
