<app-loading [isLoading]="isLoading"></app-loading>

<div *ngIf="isOpenPopThanks" class="pop-thanks">
    <div class="card"> 
        <button (click)="handleOffPopThanks()" class="dismiss" type="button">×</button> 
        <div class="header"> 
          <div class="image">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20 7L9.00004 18L3.99994 13" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
            </div> 
            <div class="content">
               <span class="title">Order validated</span> 
               <p class="message">Thank you for your purchase. you package will be delivered within 2 days of your purchase</p> 
               </div> 
               <div class="actions">
                  <button (click)="handleOffPopThanks()" class="history" type="button">Okey 🥳🥳</button> 
                  <!-- <button class="track" type="button">Track my package</button>  -->
                  </div> 
                  </div> 
                  </div>
</div>

<div class="main">
    <div class="body">
        <!-- <kendo-popup class="pop-up">
            <div class="content-popup">
                <div class="title">
                    {{"You don't have account" | uppercase}}
                </div>
            </div>
        </kendo-popup> -->


    <div class="banner1">
        <iframe
            *ngIf="isYoutube(dataBanner1?.BannerUrl) == true"
            width="100%"
            height="900"
            [src]="dataBanner1?.BannerUrl + '?controls=0&showinfo=0&modestbranding=1&mute=1&autoplay=1&loop=1&playlist=FvAmB_xBCd0&modestbranding=1&iv_load_policy=3&fs=0&rel=0' | safePipe"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
        </iframe>

        

        <div class="image-banner1"  *ngIf="isYoutube(dataBanner1?.BannerUrl) == false">
            <img src="{{dataBanner1?.BannerUrl}}" alt="">
        </div>
    
        <div class="title">
            <div class="title-head-first">
                {{dataBanner1?.Title| uppercase}}
            </div>
            <div class="title-head-second">
                {{  'From Heels to Sneakers, We ve Got Your Fit' | uppercase}}
            </div>
            <div class="btn-shop">
                <div data-tooltip="Sold out" class="button">
                    <div (click)="handleGetShoses('Main', 'full')" class="button-wrapper">
                      <div class="text">Buy Now</div>
                        <span class="icon">
                          <svg viewBox="0 0 16 16" class="bi bi-cart2" fill="currentColor" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"></path>
                    </svg>
                        </span>
                      </div>
                    </div>
            </div>
        </div>
      
    </div>

    <div class="content">
        <div class="content-featured">
            <div class="title">
                Featured
            </div>
            <div class="card-second">
                <div class="item" *ngFor="let item of dataContentSecond">
                    <div class="image-area">
                        <img class="img" src="{{item.imageUrl}}" alt="">
                    </div>
                    <div class="text">
                        {{item.text}}
                    </div>
                    <div (click)="handleGetShoses(item.route, item.type)" class="btn-shop">
                        <button class="button type1">
                            <span class="btn-txt">{{'Shop now' | uppercase}}</span>
                          </button>
                          
                    </div>
                </div>
            </div>
        </div>

        <div class="content-new-product">
            <div class="title">
                New in here
            </div>
            <div class="card">
                <div class="item" *ngFor="let product of ListProductDesc">
                    <app-ecom-product-card [product]="product"></app-ecom-product-card>
                </div>
            </div>
        </div>

        <div class="content-trending">
            <div class="title">
                Trending
            </div>
            <div class="card">
                <div *ngIf="isYoutube(dataBanner3?.BannerUrl) == false" class="image-area">
                    <img class="img" src="{{dataBanner3?.BannerUrl}}" alt="">
                </div>
                <div class="text-area">
                    <div class="slogan">
                        {{dataBanner3?.Title| uppercase}}
                    </div>
                    <div class="text">
                        Every accomplishment starts with the decision to try.  
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>

</div>

