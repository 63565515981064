<div class="item" routerLink="ecom/product-detail" (click)="handleProductClick(product)">
    <div>
        <div *ngIf="hasthumbnail">
            <div *ngFor="let img of product.ListOfImage">
                <div class="image" *ngIf="img.IsThumbnail == true">
                    <img loading="lazy" src="{{img.ImgUrl}}" alt="">
                </div>
            </div>
        </div>
        <div *ngIf="!hasthumbnail">
            <div  class="image">
                <img src="https://media.istockphoto.com/id/1354776457/vector/default-image-icon-vector-missing-picture-page-for-website-design-or-mobile-app-no-photo.jpg?s=612x612&w=0&k=20&c=w3OW0wX3LyiFRuDHo9A32Q0IUMtD4yjXEvQlqyYk9O4=" alt="">
            </div>
        </div>
       
        <div class="info-item">
            <div class="name">
                {{product.Name}}
            </div>
            <div class="type">
                {{product.BrandName}}
            </div>
    
            <div class="price-area">
                <div *ngIf="product.PriceAfterDiscount < product.Price" class="price-sale">
                    {{product.PriceAfterDiscount | number: '1.0-0'}} vnd
                </div>
                <span [ngClass]="{'price-discount': product.PriceAfterDiscount < product.Price, 'price': product.PriceAfterDiscount >= product.Price}" class="price">
                    {{ product.Price | number: '1.0-0'}} vnd
                </span>
            </div>
        </div>
    </div>
    
</div>