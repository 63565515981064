export const DataModule = [
    {
        id: 0, route: 'home', router: "", text: "New & Featured", isSelected: true
    },
    {
        id: 1, route: 'shose', router: "", text: "Men", isSelected: false
    },
    {
        id: 2, route: 'shose', router: "", text: "Women", isSelected: false
    },
    {
        id: 3, route: 'shose', router: "", text: "Sale", isSelected: false
    },
    // {
    //     id: 0, text: "New & Featured", isSelected: true
    // },
]