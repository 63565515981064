<div class="p-import-image" [ngStyle]="{
    'gap.px': gap
}">
    <div class="text-icon-container">
        <div class="text-image">{{text}}</div>
        <div style="display: flex;">
            <kendo-fileselect ImportImageDirective [restrictions]="fileRestrictions" (select)="onFileSelected($event)"
                FileSelected>
            </kendo-fileselect>
        </div>
    </div>
    <div class="image-container" *ngIf="selectedFile || imageHandle">
        <div class="img-preview example-config big-image">
            <img *ngIf="imageHandle" [src]="imageHandle.ImgUrl" [ngStyle] ="{
                'width.px' :imgWidth,
                'max-width.px' :imgWidth,
                'min-width.px' :imgMinWidth,
                'height.px' :imgHeight,
                'max-height.px' :imgHeight,
                'min-height.px' :imgMinHeight,
                'border-radius.px' :rounded,
            }" alt="Avatar"/>
            <i id="icon-delete" class="fa-regular fa-circle-xmark" (click)="delete()"></i>
        </div>
    </div>
</div>