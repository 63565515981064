export const DataSecondContent = [
    {
        id: 0, route: 'Women', type: "Gender" ,text: "Good shoes take women to great places", imageUrl: "https://img.freepik.com/premium-photo/closeup-female-legs-jeans-beige-loafers-made-genuine-leather_173815-42672.jpg"
    },
    {
        id: 3, route: 'Nike', type: "Brand", text: "Just do it.", imageUrl: "https://cdn.vuahanghieu.com/unsafe/0x0/left/top/smart/filters:quality(90)/https://admin.vuahanghieu.com/upload/news/content/2022/06/web-ban-giay-nike-chinh-hang-1-jpg-1656572535-30062022140215.jpg"
    },
    {
        id: 4, route: 'Adidas', type: "Brand", text: " Impossible is nothing.", imageUrl: "https://cdn.pixabay.com/photo/2016/06/20/21/46/sport-shoes-1470061_1280.jpg"
    },
    {
        id: 2, route: 'Sport', type: "Catelogry", text: "Chase your dreams in the right shoes", imageUrl: "https://c02.purpledshub.com/uploads/sites/41/2021/08/Alamy_2F3Y8J0-crop-ff47efc.jpg"
    },
   
    {
        id: 1, route: 'Men', type: "Gender", text: "This product is for men", imageUrl: "https://entail.upstep.com/en-assets/upstep/fit-in/700x700/choosing%20running%20shoes-1650759571648.jpg"
    },
  
   
    
    
]