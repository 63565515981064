import { Component } from '@angular/core';

@Component({
  selector: 'app-admin008-calculate-salary',
  templateUrl: './admin008-calculate-salary.component.html',
  styleUrls: ['./admin008-calculate-salary.component.scss']
})
export class Admin008CalculateSalaryComponent {

}
