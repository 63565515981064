<div *ngIf="expanded" class="body">
    <div class="content">
        <div class="title">
            We sincerely apologize
        </div>  
        <div class="choose-error-area">
            <div class="title-err">
                Please give me your problem 🤗
            </div>
            <div class="table-err">
                <div *ngFor="let data of dataError">
                    <div [ngClass]="data.select == true ? 'seleced' : 'non-selected'" (click)="handleChooseErr(data)" class="item">
                        {{data.text}}
                    </div>
                </div>
               
            </div>
            <div class="inputGroup">
                <textarea [(ngModel)]="valueErr" (resize)="false" placeholder="Type your problem" rows="8"  type="text" required="" autocomplete="off" ></textarea>
            </div>

            <div class="btn-area">
                <div (click)="toggleExpanded()"  class="btn btn-close">
                    Close
                </div>

                <div (click)="handleSendErr()" class="btn btn-accept">
                    Accept
                </div>
            </div>
        </div>
    </div>
</div>
