<div class="body" *ngIf="validateCustomer == true">
    <div class="header">
        <app-header></app-header>
    </div>
    <div class="content">
        <router-outlet></router-outlet>
      
    </div>
   
</div>



<!-- <app-ecom-also-like></app-ecom-also-like>p -->