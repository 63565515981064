<div class="box-text-area" [style.width.px]="widthBox">
    <kendo-label
    [style.width.px]="labelWidth"
    [style.color]="colorLabel"
    [style.fontSize.px]="fontsize"
    class="k-form" 
    [text]="label">
    </kendo-label>
    <kendo-textarea
    [placeholder]="placeholder"
    resizable="none"
    [ngClass]="{'readonly': readOnly}"
    [style.width.px]="width"
    [style.height.px]="height"
    [style.maxHeight.px]="height"
    [style.minHeight.px]="50"
    [style.color]="color"
    [style.border.px]="borderWidth"
    [style.borderColor]="borderColor"
    [style.borderStyle]="borderStyle"
    [style.backgroundColor]="bgColor"
    [style.borderRadius]="rounded"
    [style.fontSize.px]="fontsize"
    [(value)]="value"
    (blur)="blur()"
    [disabled]="disabled"

>
</kendo-textarea>
</div>