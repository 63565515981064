import { Component } from '@angular/core';

@Component({
  selector: 'app-admin002-manage-module',
  templateUrl: './admin002-manage-module.component.html',
  styleUrls: ['./admin002-manage-module.component.scss']
})
export class Admin002ManageModuleComponent {

}
