<kendo-drawer-container *ngIf="checkPermission()">
    <kendo-drawer #drawer [mode]="expandMode" [position]="positionDrawer" [width]="widthDrawer" [(expanded)]="expanded">
        <ng-template kendoDrawerTemplate>
            <div class="drawer-custom-container">
                <!-- Header của drawer -->
                <div class="header-drawer">
                    <i class="fa-solid fa-angles-left icon-back" (click)="toggleDrawerToAdd()"></i>
                    <div class="label-detail-coupon" *ngIf="selectedCoupon.Code !== -1">Chi tiết khuyến mãi</div>
                    <div class="label-add-coupon" *ngIf="selectedCoupon.Code === -1">Thêm mới khuyến mãi</div>
                </div>

                <!-- Content của drawer -->
                <div class="content-drawer">
                    <!-- Mã khuyến mãi -->
                    <component-text-input #idCoupon [placeholder]="'Nhập mã khuyến mãi...'"
                        [value]="selectedCoupon.IdCoupon" [widthTextBox]="350" [widthBlock]="510"
                        label="Mã khuyến mãi"></component-text-input>

                    <!-- Ngày áp dụng -->
                    <div class="date-apply-block">
                        <div class="label">Thời gian áp dụng</div>
                        <div class="date">
                            <component-datepicker #startDateCouponView [defaultDate]="startDateCoupon"
                                [minDate]="minDateCoupon" [maxDate]="endDateCoupon"
                                (datePicked)="getDateDetailCoupon($event, 'start')"></component-datepicker>
                            <span>Đến</span>
                            <component-datepicker #endDateCouponView [defaultDate]="endDateCoupon"
                                [minDate]="startDateCoupon" [maxDate]="maxDateCoupon"
                                (datePicked)="getDateDetailCoupon($event, 'end')"></component-datepicker>
                        </div>
                    </div>

                    <!-- Số lượng -->
                    <div class="quantity-block">
                        <!-- Số lượng -->
                        <component-text-input #quantityCoupon [value]="selectedCoupon.Quantity" typeValue="number"
                            [widthTextBox]="150" [widthBlock]="230" label="Số lượng"
                            (getValue)="getQuantityCoupon($event)"></component-text-input>
                        <!-- Còn lại -->
                        <component-text-input #remainingQuantityCoupon [value]="selectedCoupon.RemainingQuantity"
                            [readOnly]="true" typeValue="number" [widthTextBox]="150" [widthBlock]="230"
                            label="Còn lại"></component-text-input>
                    </div>

                    <!-- Đơn hàng tối thiểu được áp dụng -->
                    <component-text-input #minBillPrice [value]="selectedCoupon.MinBillPrice" class="text-input-min"
                        typeValue="number" [widthTextBox]="250" [widthBlock]="510"
                        label="Đơn hàng tối thiểu được áp dụng"></component-text-input>

                    <!-- Chọn loại khuyến mãi -->
                    <component-text-dropdown #couponType
                        [value]="{Code: selectedCoupon.CouponType, Type: checkCouponType(selectedCoupon.CouponType)}"
                        (getValue)="getCouponType($event)" [widthTextBox]="250" [widthBlock]="510"
                        label="Loại khuyến mãi" [defaultItem]="defaultCouponType" [borderWidth]="1"
                        borderColor="rgb(182, 182, 182)" [heightTextBox]="30" [listItem]="listCouponType"
                        textField="Type" valueField="Code"></component-text-dropdown>

                    <!-- Đối với loại khuyến mãi là theo phần trăm -->
                    <ng-container *ngIf="selectedCouponType === 0">
                        <!-- Giảm tối đa và % giảm -->
                        <div class="percent-discount-block">
                            <!-- Giảm tối đa -->
                            <component-text-input #maxBillDiscount class="input-max-bill-discount" [value]="selectedCoupon.MaxBillDiscount"
                                typeValue="number" [widthTextBox]="130" [widthBlock]="230"
                                label="Giảm tối đa"></component-text-input>
                            <!-- Giảm % -->
                            <component-text-input #percentDiscount [value]="selectedCoupon.PercentDiscount"
                                class="input-percent-discount" typeValue="number" [widthTextBox]="150"
                                [widthBlock]="230" label="Giảm %"></component-text-input>
                        </div>
                    </ng-container>

                    <!-- Đối với loại khuyến mãi là giảm giá trực tiếp -->
                    <ng-container *ngIf="selectedCouponType === 1">
                        <!-- Giảm giá trực tiếp -->
                        <component-text-input #directDiscount [value]="selectedCoupon.DirectDiscount"
                            class="input-direct-discount" typeValue="number" [widthTextBox]="250" [widthBlock]="510"
                            label="Giảm trực tiếp"></component-text-input>
                    </ng-container>

                    <!-- Nhóm khách hàng áp dụng -->
                    <component-text-dropdown #applyTo
                        [value]="{Code: selectedCoupon.ApplyTo, Group: checkApplyToCustomer(selectedCoupon)}"
                        (getValue)="getGroupCustomer($event)" [widthTextBox]="300" [widthBlock]="510"
                        label="Nhóm khách hàng áp dụng" [defaultItem]="defaultGroupCustomer" [borderWidth]="1"
                        borderColor="rgb(182, 182, 182)" [heightTextBox]="30" [listItem]="listGroupCustomer"
                        textField="Group" valueField="Code"></component-text-dropdown>

                    <!-- Mô tả -->
                    <component-text-area #description [height]="70" [value]="selectedCoupon.Description"
                        [label]="'Mô tả khuyến mãi'" [width]="350" [widthBox]="510"
                        [fontsize]="13"></component-text-area>

                    <!-- Xem trước -->
                    <div class="review-block">
                        <div class="label">Xem trước</div>
                        <div class="review">
                            <component-coupon [coupon]="reviewCoupon"></component-coupon>
                        </div>
                    </div>

                    <!-- Trạng thái khuyến mãi -->
                    <component-text-input [value]="checkStatus(selectedCoupon)" [readOnly]="true" typeValue="number"
                        [widthTextBox]="350" [widthBlock]="510" label="Trạng thái khuyến mãi"></component-text-input>

                    <!-- Giai đoạn hiện tại -->
                    <component-text-input [value]="checkStage(selectedCoupon)" [readOnly]="true" typeValue="number"
                        [widthTextBox]="350" [widthBlock]="510" label="Giai đoạn hiện tại"></component-text-input>

                    <div class="button-block">
                        <!-- Button lưu bản nháp -->
                        <ng-container *ngIf="selectedCoupon.Code === -1">
                            <component-button class="button-update" [borderWidth]="2" color="#2557A0"
                                borderColor="#2557A0" borderStyle="solid" [value]="1"
                                classIconFontAwesome="fa-floppy-disk" [height]="36" bgColor="#fff"
                                text="Lưu bản nháp" (sendValue)="addCoupon()"></component-button>
                        </ng-container>

                        <!-- Button cập nhật -->
                        <ng-container *ngIf="selectedCoupon.Status === 0 && selectedCoupon.Code !== -1">
                            <component-button class="button-update" [borderWidth]="2" color="#2557A0"
                                borderColor="#2557A0" borderStyle="solid" [value]="1" (sendValue)="updateDetailCoupon()"
                                classIconFontAwesome="fa-floppy-disk" [height]="36" bgColor="#fff"
                                text="Cập nhật"></component-button>
                        </ng-container>

                        <!-- Button gửi duyệt -->
                        <ng-container *ngIf="selectedCoupon.Status === 0">
                            <component-button class="button-send" [borderWidth]="2" color="#069DF1"
                                borderColor="#069DF1" borderStyle="solid" [value]="1" classIconFontAwesome="fa-share"
                                [height]="36" bgColor="#fff" text="Gửi duyệt" (sendValue)="updateStatusDrawer($event)"></component-button>
                        </ng-container>

                        <!-- Button phê duyệt -->
                        <ng-container *ngIf="selectedCoupon.Status === 1">
                            <component-button class="button-approve" [borderWidth]="2" color="#09880E"
                                borderColor="#09880E" borderStyle="solid" [value]="2"
                                classIconFontAwesome="fa-circle-check" [height]="36" bgColor="#fff"
                                text="Phê duyệt" (sendValue)="updateStatusDrawer($event)"></component-button>
                        </ng-container>

                        <!-- Button ngưng áp dụng -->
                        <ng-container *ngIf="selectedCoupon.Status === 2">
                            <component-button class="button-disapprove" [borderWidth]="2" color="#FF1D1D"
                                borderColor="#FF1D1D" borderStyle="solid" [value]="3"
                                classIconFontAwesome="fa-circle-minus" [height]="36" bgColor="#fff"
                                text="Ngừng áp dụng" (sendValue)="updateStatusDrawer($event)"></component-button>
                        </ng-container>
                    </div>
                </div>

                <!-- Footer của drawer -->
                <div class="footer-drawer">
                    <!-- Button quay lại để đóng drawer -->
                    <component-button class="button-back" [borderWidth]="2" color="#000" borderColor="#000"
                        borderStyle="solid" [value]="0" (sendValue)="toggleDrawerToAdd()" [height]="36"
                        classIconFontAwesome="fa-reply" bgColor="#fff" text="Quay lại"></component-button>
                </div>
            </div>
        </ng-template>
    </kendo-drawer>

    <kendo-drawer-content>
        <div class="content-container">
            <!-- Header Filter theo trạng thái -->
            <div class="header-filter-status">
                <div class="block-filter-status">
                    <div class="label-filter">Lọc dữ liệu</div>
                    <!-- Danh sách các trạng thái -->
                    <div class="list-status">
                        <component-checkboxlist #status (getListChecked)="this.getValueFromCheckList($event, 'status')"
                            [valueField]="'Code'" [textField]="'Status'"
                            [listCheckBox]="listStatusCoupon"></component-checkboxlist>
                    </div>
                </div>

                <!-- Button thêm khuyến mãi-->
                <component-button class="button-add" [borderWidth]="2" color="#09880E" borderColor="#09880E"
                    borderStyle="solid" [value]="0" (sendValue)="toggleDrawerToAdd()" [height]="36"
                    classIconFontAwesome="fa-plus" bgColor="#fff" text="Thêm mới"></component-button>
            </div>

            <!-- Header Filter theo giai đoạn -->
            <div class="header-filter-stage">
                <div class="block-filter-stage">
                    <div class="label-delete-filter" (click)="resetFilter()">Xóa bộ lọc</div>
                    <component-search-bar #search [width]="400" (getValue)="setFilterSearch($event)"
                        [placeholder]="'Tìm kiếm theo mã khuyến mãi'"></component-search-bar>
                    <div class="list-stage">
                        <component-checkboxlist #stage (getListChecked)="this.getValueFromCheckList($event, 'stage')"
                            [valueField]="'Code'" [textField]="'Status'"
                            [listCheckBox]="listStageCoupon"></component-checkboxlist>
                    </div>
                </div>

                <div class="block-filter-date">
                    <component-datepicker #rangeDateStart [minDate]="minDate" [maxDate]="endDate"
                        (datePicked)="getDateFromDatePicker($event, 'start')"></component-datepicker>
                    <div class="line"></div>
                    <component-datepicker #rangeDateEnd [minDate]="startDate" [maxDate]="maxDate"
                        (datePicked)="getDateFromDatePicker($event, 'end')"></component-datepicker>
                </div>

                <div class="block-filter-group">
                    <div class="label-filter-group">Đối tượng</div>
                    <component-text-dropdown #group (getValue)="setFilterGroupCustomer($event)" [label]="''"
                        [listItem]="listGroupCustomerApply" [defaultItem]="defaultGroupCustomerApply"
                        [valueField]="'Code'" [textField]="'Group'"></component-text-dropdown>
                </div>
            </div>

            <div class="label-list-coupon">
                Danh sách khuyến mãi
            </div>

            <div class="grid-custom-container">
                <kendo-grid [data]="listCoupon" [skip]="gridState.skip" [loading]="isLoading"
                    (pageChange)="onPageChange($event)" [pageSize]="gridState.take"
                    [pageable]="{ type: 'numeric', pageSizes: true }" [selectable]="{ checkboxOnly: true }">

                    <!-- Checkbox -->
                    <!-- <kendo-grid-checkbox-column [showSelectAll]="true" [width]="40"></kendo-grid-checkbox-column> -->

                    <!-- Mã khuyến mãi -->
                    <kendo-grid-column title="Khuyến mãi" [width]="150">
                        <ng-template kendoGridCellTemplate let-item>
                            <div class="column-2">
                                {{item.IdCoupon}}
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- Loại khuyến mãi -->
                    <kendo-grid-column title="Loại khuyến mãi" [width]="180">
                        <ng-template kendoGridCellTemplate let-item>
                            <div class="column-3">
                                <div class="type">{{checkCouponType(item.CouponType)}}</div>
                                <div class="value-discount">{{checkValueDiscount(item)}}</div>
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- Điều kiện áp dụng -->
                    <kendo-grid-column title="Điều kiện áp dụng" [width]="150">
                        <ng-template kendoGridCellTemplate let-item>
                            <div class="column-4">
                                <div class="min-bill-price">Đơn tối thiểu: {{formatCurrency(item.MinBillPrice)}}</div>
                                <ng-container *ngIf="item.PercentDiscount && !item.DirectDiscount">
                                    <div class="max-bill-discount">Giảm tối đa: {{formatCurrency(item.MaxBillDiscount)}}
                                    </div>
                                </ng-container>
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- Thời gian áp dụng -->
                    <kendo-grid-column title="Thời gian áp dụng" [width]="150">
                        <ng-template kendoGridCellTemplate let-item>
                            <div class="column-5">
                                <div class="start-date">Từ: {{formatDateToDisplay(item.StartDate)}}</div>
                                <div class="end-date">Đến: {{formatDateToDisplay(item.EndDate)}}</div>
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- Nhóm khách hàng áp dụng -->
                    <kendo-grid-column title="Khách hàng áp dụng" [width]="130">
                        <ng-template kendoGridCellTemplate let-item>
                            <div class="column-6">
                                <div class="apply-to">{{checkApplyToCustomer(item)}}</div>
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- Số lượng -->
                    <kendo-grid-column title="Số lượng" [width]="130">
                        <ng-template kendoGridCellTemplate let-item>
                            <div class="column-7">
                                <div class="quantity">Gốc: {{item.Quantity}}</div>
                                <div class="remaining-quantity">Còn lại: {{item.RemainingQuantity}}</div>
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- Trạng thái và giai đoạn -->
                    <kendo-grid-column title="Trạng thái" [width]="130">
                        <ng-template kendoGridCellTemplate let-item>
                            <div class="column-8">
                                <div class="status" [style.color]="checkStatus(item) | statusColor">
                                    {{checkStatus(item)}}</div>
                                <div class="stage">{{checkStage(item)}}</div>
                            </div>
                        </ng-template>
                    </kendo-grid-column>


                    <!-- ToolBox -->
                    <kendo-grid-column [sticky]="true" [resizable]="true" [width]="40">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="tool-box-container" (click)="onClickToolBox(dataItem, $event)">
                                <component-button class="button-tool-box" [width]="30" [height]="30" [size]="15"
                                    color="#000" bgColor="#fff" classIconFontAwesome="fa-ellipsis"
                                    text=""></component-button>
                                <div class="list-action" *ngIf="selectedCouponCode === dataItem.Code">
                                    <component-dropdown-action (sendValue)="updateStatusCoupon(dataItem, $event)"
                                        [listItem]="getListChangeStatus(dataItem.Status)"></component-dropdown-action>
                                </div>
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <!-- Paging -->
                    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                        <kendo-pager-page-sizes pagerGrid [pageSizes]="listPageSize"></kendo-pager-page-sizes>
                        <div class="pager-buttons">
                            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                            <kendo-pager-numeric-buttons [buttonCount]="3"></kendo-pager-numeric-buttons>
                            <kendo-pager-next-buttons></kendo-pager-next-buttons>
                        </div>
                    </ng-template>
                </kendo-grid>
            </div>
        </div>
    </kendo-drawer-content>
</kendo-drawer-container>