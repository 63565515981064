<div class="componet-search-bar">
    <kendo-textbox 
    [ngStyle]="{
    'width.px': width,
    'height.px': height,
    'color': color,
    'background-color': bgColor,
    'border-radius.px': rounded,
    'border-width.px': borderWidth,
    'border-color': borderColor,
    'padding-left.px': paddingLeft,
    'padding-right.px': paddingRight,
    'box-shadow': boxShadow}"
    [placeholder]='placeholder'
    (keydown.enter)="sendValueInput()"
    showSuccessIcon="initial"
    [(value)]="valueSearch"
    showErrorIcon="initial">
    </kendo-textbox>
    
    <ng-container *ngIf="hasIcon">
        <svg class="icon-search" (click)="sendValueInput()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>
    </ng-container>

    <ng-container *ngIf="valueSearch">
        <svg class="icon-delete" (click)="clearValue()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
    </ng-container>
</div>
