<div class="bg-detail-cart" *ngIf="checkPermission()">
    <div class="component-detail-cart">
        <div class="header-detail">
            <i id="icon-back" class="fa-solid fa-angles-left"></i>
            <p>Chi tiết hóa đơn</p>
        </div>

        <div class="body-detail">
            <div class="box-info">
                <div>
                    <ng-container *ngIf="!isAdd">
                        <div class="codeBill">Mã đơn {{itemBill.Code}}</div>
                        <div class="createAtBill">{{formattedCreateAt(itemBill.CreateAt)}}</div>
                    </ng-container>
                    <!-- <div *ngIf="!isAdd" class="box-button-update">
                        <component-button *ngIf="!isEdit" class="button-edit" [text]="'Chỉnh sửa'"
                            [classIconFontAwesome]="'fa-pencil'" [color]="'rgb(255, 29, 29)'" [bgColor]="'white'"
                            [borderColor]="'rgb(255, 29, 29)'" [rounded]="3" [borderWidth]="2" [borderStyle]="'solid'">
                        </component-button>
                        <ng-container *ngIf="isEdit">
                            <component-button class="button-update" [text]="'Cập nhật'"
                                [classIconFontAwesome]="'fa-floppy-disk'" [color]="'rgb(37, 87, 160)'"
                                [bgColor]="'white'" [borderColor]="'rgb(37, 87, 160)'" [rounded]="3" [borderWidth]="2"
                                [borderStyle]="'solid'">
                            </component-button>
                            <component-button class="button-restore" [text]="'Khôi phục'"
                                [classIconFontAwesome]="'fa-arrow-rotate-left'" [color]="'rgb(88, 88, 88)'"
                                [bgColor]="'white'" [borderColor]="'rgb(88, 88, 88)'" [rounded]="3" [borderWidth]="2"
                                [borderStyle]="'solid'">
                            </component-button>
                        </ng-container>
                    </div> -->
                    <div *ngIf="isAdd" class="header-addBill">
                        <!-- <p class="resetFilter">Xóa bộ lọc</p>
                        <kendo-autocomplete #autocomplete [data]="" [filterable]="true">
                        </kendo-autocomplete> -->
                        <div class="box-button-addBill">
                            <component-button class="button-addBill" [color]="'#09880E'" [bgColor]="'white'"
                                [text]="'Thêm mới hóa đơn'" [borderColor]="'#09880E'" [rounded]="3" [borderWidth]="2"
                                [borderStyle]="'solid'">
                            </component-button>
                        </div>
                    </div>
                </div>
                <component-form [text]="'THÔNG TIN HÓA ĐƠN'">
                    <div *ngIf="!isAdd" class="content-form">
                        <div class="column column1">
                            <component-text-input [label]="'Tên khách hàng'" [widthBlock]="430"
                                [value]="itemBill.CustomerName" [disabled]="isDisabled"></component-text-input>
                            <component-text-input [label]="'Số điện thoại'" [widthBlock]="430"
                                [value]="itemBill.OrdererPhoneNumber" [disabled]="isDisabled"></component-text-input>
                            <!-- <component-text-dropdown #method [label]="'Phương thức'" [widthBlock]="430"
                                [widthTextBox]="300" [heightTextBox]="30" [listItem]="PaymentMethodDropDown"
                                textField="Method" valueField="Code" [value]="setDTOPaymentMethod()"
                                [disabled]="isDisabled">
                            </component-text-dropdown> -->
                            <component-text-input [label]="'Số người nhận'" [widthBlock]="430"
                                [value]="itemBill.PhoneNumber" [disabled]="isDisabled"></component-text-input>
                            <component-text-input [label]="'Phương thức'" [widthBlock]="430"
                                [value]="setDTOPaymentMethod().Method" [disabled]="isDisabled"></component-text-input>
                        </div>

                        <div class="column column2">
                            <component-text-input [label]="'Tỉnh, thành phố'" [widthBlock]="430"
                                [value]="provinceBiding" [disabled]="isDisabled"></component-text-input>
                            <component-text-input [label]="'Quận, huyện'" [widthBlock]="430" [value]="districtBiding"
                                [disabled]="isDisabled"></component-text-input>
                            <component-text-input [label]="'Xã, thị trấn'" [widthBlock]="430" [value]="wardBiding"
                                [disabled]="isDisabled"></component-text-input>
                            <component-text-input [label]="'Đường'" [widthBlock]="430" [value]="roadBiding"
                                [disabled]="isDisabled"></component-text-input>
                            <component-text-input [label]="'Địa chỉ cụ thể'" [widthBlock]="430"
                                [value]="getSpecialAddress(itemBill.ShippingAddress)"
                                [disabled]="isDisabled"></component-text-input>
                        </div>

                    </div>
                    <div *ngIf="isAdd" class="content-form">
                        <div class="column column1">
                            <component-text-input #name [label]="'Tên khách hàng'"
                                [widthBlock]="430"></component-text-input>
                            <component-text-dropdown #province [label]="'Tỉnh, thành phố'" [value]="provinceDTOBiding"
                                [isLoading]="isLoadingProvince" [widthBlock]="430" [widthTextBox]="300"
                                [heightTextBox]="30" [listItem]="listProvince" textField="province_name"
                                valueField="province_name" [hasEmitValueChange]="1"
                                (valueChange)="handleChangeProvince($event)"
                                [defaultItem]="defaultValueProvince"></component-text-dropdown>
                                
                            <component-text-dropdown #district [label]="'Quận, huyện'" [value]="districtDTOBiding" [isLoading]="isLoadingDistrict"
                                [widthBlock]="430" [widthTextBox]="300" [heightTextBox]="30" [listItem]="listDistrict"
                                textField="district_name" valueField="district_name" [hasEmitValueChange]="1"
                                (valueChange)="handleChangeDistrict($event)" [disabled]="isDisableDistrict"
                                [defaultItem]="defaulValueDistrict"></component-text-dropdown>

                            <component-text-dropdown #ward [label]="'Xã, thị trấn'" [value]="wardDTOBiding" [isLoading]="isLoadingWard"
                                [widthBlock]="430" [widthTextBox]="300" [heightTextBox]="30" [listItem]="listWard"
                                textField="ward_name" valueField="ward_name" [hasEmitValueChange]="1"
                                (valueChange)="handleChangeWard($event)" [disabled]="isDisableWard"
                                [defaultItem]="defaultValueWard"></component-text-dropdown>
                            <div class="input-voucher">
                                <span class="lable-input-voucher">Voucher</span>
                                <kendo-combobox #coupon [data]="listDTOCoupon" textField="IdCoupon"
                                    valueField="IdCoupon" value="IdCoupon" [valuePrimitive]="true" [filterable]="true"
                                    (filterChange)="handleCoupon($event)" [disabled]="isDisabledVoucher"
                                    placeholder="Nhập voucher" (valueChange)="searchIdCoupon($event)">
                                    <ng-template kendoComboBoxHeaderTemplate>
                                        <div class="filter-row"
                                            style="padding-left: 8px; font-weight: 600; padding-top: 5px; padding-bottom: 5px;">
                                            <span class="filter-Voucher">Voucher</span>
                                            <span class="filter-Min">Giá giảm</span>
                                            <span class="filter-Max">Tối đa</span>
                                        </div>
                                    </ng-template>
                                    <ng-template kendoComboBoxItemTemplate let-dataItem>
                                        <div class="filter-row">
                                            <span class="filter-Voucher" [title]="dataItem.IdCoupon" >{{dataItem.IdCoupon}}</span>
                                            <span class="filter-Min" [title]="formatCouponType(dataItem)">{{formatCouponType(dataItem)}}</span>
                                            <span class="filter-Max" *ngIf="dataItem.MaxBillDiscount" [title]="formatCurrency(dataItem.MaxBillDiscount)">{{formatCurrency(dataItem.MaxBillDiscount)}}</span>
                                            <span class="filter-Max" *ngIf="!dataItem.MaxBillDiscount" [title]="formatCouponType(dataItem)">{{formatCouponType(dataItem)}}</span>
                                        </div>
                                    </ng-template>
                                </kendo-combobox>
                            </div>

                        </div>

                        <div class="column column2">
                            <div class="column-checkbox">
                                <!-- <div class="box-checkbox has-account">
                                    <input type='checkbox' kendoCheckBox (change)="handleCheckHasAccount($event)">
                                    <kendo-label class="k-checkbox-label" text="Khách có tài khoản"></kendo-label>
                                </div> -->
                                <div class="box-checkbox has-account">
                                    <input type='checkbox' kendoCheckBox (change)="handleCheckHasRecipient($event)">
                                    <kendo-label class="k-checkbox-label" text="Có người nhận thay"></kendo-label>
                                </div>
                            </div>
                            <!-- <component-text-input #ordererPhoneNumber *ngIf="!hasAccount" [label]="'Số điện thoại'"
                                [widthBlock]="430"></component-text-input> -->
                            <div class="input-search-sdt">
                                <span class="lable-input-voucher">Số điện thoại</span>
                                <component-search-bar #ordererPhoneNumberSearch [height]="30" [rounded]="0" [placeholder]="'Tìm theo số điện thoại'"
                                (getValue)="handleFilterCustomer($event)"></component-search-bar>
                            </div>
                            <component-text-input #phoneNumber  class="input-recipient" [label]="'Số người nhận'" [disabled]="!hasRecipient"
                                [widthBlock]="430"></component-text-input>
                            <component-text-input #road [label]="'Đường'" [widthBlock]="430" [disabled]="isDisableSpecific" [value]="roadBiding"
                                (getValue)="setNewAddress()"></component-text-input>
                            <component-text-input #specific [label]="'Địa chỉ cụ thể'" [widthBlock]="430" [value]="specialAddress"
                                [disabled]="isDisableSpecific" (getValue)="setNewAddress()"></component-text-input>
                        </div>
                    </div>
                </component-form>
            </div>

            <div class="showListDetail">
                <div class="header-search-product" *ngIf="isAdd">
                    <!-- <component-search-bar #search class="searchBar" placeholder="Tìm kiếm theo mã sản phẩm"
                        [width]="400" (getValue)="searchIdProduct($event)"></component-search-bar> -->
                    <kendo-combobox [data]="listDTOProduct" textField="Name" valueField="IdProduct" value="IDProduct"
                        [valuePrimitive]="true" [filterable]="true" (filterChange)="handleFilter($event)"
                        placeholder="Nhập mã hoặc tên sản phẩm" (valueChange)="searchIdProduct($event)">
                        <ng-template kendoComboBoxHeaderTemplate>
                            <div class="filter-row" style="padding-left: 5px; font-weight: 600;">
                                <span class="filter-img">Hình ảnh</span>
                                <span class="filter-Name">Tên sản phẩm</span>
                                <span class="filter-IdProduct">Mã sản phẩm</span>
                                <!-- <span **ngIf="dataItem.Discount" class="template">{{ dataItem.Discount }}</span> -->
                                <span class="filter-Price">Đơn giá</span>
                                <span class="filter-Stock">Số lượng</span>
                            </div>
                        </ng-template>
                        <ng-template kendoComboBoxItemTemplate let-dataItem>
                            <div class="filter-row">
                                <div class="filter-img">
                                    <img class="image" [src]="dataItem.ThumbnailImg" alt="">
                                </div>
                                <span class="filter-Name">{{ dataItem.Name}}</span>
                                <span class="filter-IdProduct">{{ dataItem.IdProduct }}</span>
                                <!-- <span **ngIf="dataItem.Discount" class="template">{{ dataItem.Discount }}</span> -->
                                <span class="filter-Price">{{ formatCurrency(dataItem.PriceAfterDiscount) }}</span>
                                <span class="filter-Stock">{{ dataItem.Stock }}</span>
                            </div>
                        </ng-template>
                    </kendo-combobox>

                    <component-button *ngIf="!isProcessAdd" class="button-addDetailBill" [text]="'Thêm mới sản phẩm'"
                        [color]="'#09880E'" [bgColor]="'white'" [borderColor]="'#09880E'" [rounded]="3"
                        [borderWidth]="2" [borderStyle]="'solid'"></component-button>
                </div>
                <div class="grid-custom-container">
                    <ng-container *ngIf="!isAdd">
                        <kendo-grid [data]="listBillInfo">
                            <kendo-grid-column field="ImageURL" title="Hình ảnh" [width]="90">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="column column-image">
                                        <img class="image" [src]="dataItem.ImageURL" alt="">
                                    </div>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="Mã và tên sản phẩm">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="column column-id-name">
                                        <div class="idProduct">{{dataItem.IDProduct}}</div>
                                        <div class="nameProduct">{{dataItem.Name}}</div>
                                    </div>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="Size" title="Size" [width]="100">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="column Size">
                                        <div>{{dataItem.Size}}</div>
                                    </div>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="Price" title="Đơn giá" [width]="110">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="column-Price">
                                        <div>{{formatCurrency(dataItem.Price)}}</div>
                                        <div *ngIf="dataItem.Discount" [style.color]="'red'">-{{dataItem.Discount}}%
                                        </div>
                                    </div>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="Quantity" title="Số lượng" [width]="90">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="column Quantity">
                                        <div>{{dataItem.Quantity}}</div>
                                    </div>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="TotalPrice" title="Tổng tiền" [width]="130">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="column TotalPrice">
                                        <div>{{formatCurrency(dataItem.TotalPrice)}}</div>
                                    </div>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="Status" title="Trạng thái" [width]="200">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="column-Status">
                                        <div [style.color]="formatStatus(dataItem.Status) | statusColor">
                                            {{formatStatus(dataItem.Status)}}
                                        </div>
                                        <div class="text-note" *ngIf="dataItem.Note !== ''" [title]="dataItem.Note">
                                            {{dataItem.Note}}</div>
                                    </div>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column class="column-button" [width]="50">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="block-Action">
                                        <div (click)="ClickButtonAction(dataItem.Code, $event, dataItem.Status)"
                                            class="block-buttonAction">
                                            <component-button class="button-action" [value]="dataItem.Code" text=""
                                                classIconFontAwesome="fa-ellipsis" [width]="30" [height]="30">
                                            </component-button>
                                        </div>
                                        <div *ngIf="isClickButton[dataItem.Code]" class="dropDown-action" tabindex="0">
                                            <component-dropdown-action
                                                (sendValue)="clickDropDownAction(dataItem, $event)"
                                                [listItem]="listNextStatus"></component-dropdown-action>
                                        </div>
                                    </div>
                                </ng-template>
                            </kendo-grid-column>

                        </kendo-grid>
                    </ng-container>
                    <ng-container *ngIf="isAdd">
                        <div class="grid-listProduct">
                            <kendo-grid [data]="listProduct">
                                <kendo-grid-column field="ImageURL" title="Hình ảnh" [width]="100">
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="column column-image">
                                            <img class="image" [src]="dataItem.ThumbnailImg" alt="">
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column title="Mã và tên sản phẩm">
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="column column-id-name">
                                            <div class="idProduct">{{dataItem.IdProduct}}</div>
                                            <div class="nameProduct">{{dataItem.Name}}</div>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="Size" title="Size" [width]="160">
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="column Size">
                                            <component-text-dropdown #method [widthBlock]="70" [heightTextBox]="30"
                                                [rounded]="3" [listItem]="listOfSize" textField="Size" valueField="Code"
                                                (getValue)="getStockOfSize($event)">
                                            </component-text-dropdown>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="Price" title="Đơn giá" [width]="100">
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="column-Price">
                                            <div>{{formatCurrency(dataItem.Price)}}</div>
                                            <div *ngIf="dataItem.Discount" [style.color]="'red'">-{{dataItem.Discount}}%
                                            </div>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="Quantity" title="Số lượng" [width]="120">
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="column-Quantity">
                                            <component-button [classIconFontAwesome]="'fa-minus'" [width]="20"
                                                [text]="''" (click)="decreaseQuantity()"></component-button>
                                            <input class="inputQuantity" [type]="'number'" [min]="0"
                                                [max]="stockOfSizeSelected" [(ngModel)]="inputQuantity"
                                                (input)="checkStockOfSizeSelected()">
                                            <component-button [classIconFontAwesome]="'fa-plus'" [width]="20"
                                                [text]="''" (click)="increaseQuantity()"></component-button>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="TotalPrice" title="Tổng tiền" [width]="140">
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="column TotalPrice">
                                            <div>{{formatCurrency(totalPriceOfProduct)}}</div>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column class="column-buttonOfAdd" [width]="110">
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="block-Action">
                                            <component-button class="button-x" text="" classIconFontAwesome="fa-xmark"
                                                [width]="30" [height]="30" (click)="removeProductOfList(rowIndex)">
                                            </component-button>
                                            <component-button class="button-accept" text=""
                                                classIconFontAwesome="fa-check" [width]="30" [height]="30">
                                            </component-button>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>

                            </kendo-grid>
                        </div>
                        <!-- GridListProductInCart -->
                        <div class="labelProductInCart">Sản phẩm hóa đơn</div>
                        <div class="grid-ListProductInCart">
                            <kendo-grid [data]="listProductsInCart">
                                <kendo-grid-column [width]="100">
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="column column-image">
                                            <img class="image" [src]="dataItem.Product.ThumbnailImg" alt="">
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column>
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="column column-id-name">
                                            <div class="idProduct">{{dataItem.Product.IdProduct}}</div>
                                            <div class="nameProduct">{{dataItem.Product.Name}}</div>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column [width]="120">
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="column Size">
                                            <div class="sizeProduct">{{dataItem.SizeSelected.Size}}</div>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column [width]="130">
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="column-Price">
                                            <div>{{formatCurrency(dataItem.Product.Price)}}</div>
                                            <div *ngIf="discountOfEeachId[dataItem.Product.IdProduct]"
                                                [style.color]="'red'">
                                                -{{discountOfEeachId[dataItem.Product.IdProduct]}}%</div>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column [width]="120">
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="column-Quantity">
                                            <div class="quantityProduct">{{dataItem.Quantity}}</div>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column [width]="140">
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="column TotalPrice">
                                            <div>{{formatCurrency(dataItem.TotalPriceOfProduct)}}</div>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column class="column-buttonOfAdd" [width]="110">
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="block-Action">
                                            <component-button class="button-x" text="" classIconFontAwesome="fa-xmark"
                                                [width]="30" [height]="30" (click)="removeProductInCart(dataItem)">
                                            </component-button>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>

                            </kendo-grid>
                        </div>

                    </ng-container>
                </div>
            </div>
        </div>


        <div class="footer-detail">
            <component-text-area *ngIf="!isAdd" [label]="'Ghi chú'" [widthBox]="540" [height]="80" [width]="470"
                [fontsize]="13" [placeholder]="itemBill.Note"></component-text-area>
            <div></div>
            <div class="box-totalBill">
                <div class="row-voucher" *ngIf="!isAdd">
                    <span class="label-voucher">Voucher</span>
                    <span *ngIf="itemBill.CouponApplied" class="voucher" title="{{itemBill.CouponApplied}}">{{itemBill.CouponApplied}}</span>
                    <span *ngIf="!itemBill.CouponApplied" class="voucher">Không có</span>
                </div>
                <div class="row-voucher" *ngIf="!isAdd">
                    <span class="label-voucher">Khuyến mãi</span>
                    <span *ngIf="itemBill.CouponDiscount" class="voucher" style="color: red;">-
                        {{formatCurrency(itemBill.CouponDiscount)}}</span>
                    <span *ngIf="!itemBill.CouponDiscount" class="voucher" style="color: red;">-
                        {{formatCurrency(0)}}</span>
                </div>
                <div class="row-voucher" *ngIf="isAdd">
                    <span class="label-voucher">Khuyến mãi</span>
                    <span class="voucher" style="color: red;">- {{formatCurrency(numberCoupon)}}</span>
                </div>
                <div class="row-totalBill">
                    <span class="label-total">Tổng tiền</span>
                    <span *ngIf="!isAdd" class="totalBill">{{formatCurrency(itemBill.TotalBill)}}</span>
                    <span *ngIf="isAdd" class="totalBill">{{formatCurrency(totalPrictOfBill)}}</span>
                </div>

            </div>
        </div>
    </div>
</div>
<div *ngIf="isShowAlert" class="popUp">
    <component-popup-confirm [textHeader]="'Thông báo'" [classIconFontAwesome]="objItemStatus.icon"
        [text]="objItemStatus.text" (sendValue)="updateStatusBillInfo($event)" (sendReason)="receive($event)"
        [value]="objItemStatus.value"></component-popup-confirm>
</div>