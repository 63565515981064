<div 
    class="statistics-ctn" 
    [ngStyle]="{'border': isSelected ? '2px solid ' + color : '', 'boxShadow': boxShadow}" 
    [ngClass]="{'active': isSelected}" 
    (click)="onClickItem()">
    <div class="block">
        <div 
            class="value" 
            [title]="valueField" 
            [ngStyle]="{'background-color': isSelected ? color : '#fff', 
                        'color': isSelected ? '#fff' : color, 
                        'border': !isSelected ? '1px solid ' + color : '1px solid #fff'}">
            {{ currentCount }}
        </div>
        <div 
            class="text" 
            [title]="textField" 
            [ngStyle]="{'color': color}">
            {{ textField }}
        </div>
    </div>
</div>
