<div class="admin001-info-customer" *ngIf="checkPermission()">
    <div class="header-filter-statistics">
        <div class="label-filter">LỌC DỮ LIỆU</div>

        <!-- Tổng số lượng khách hàng -->
        <component-statistics #accountActive (getSelected)="pushStatisticsToAllStatistics(filterAllCustomer, $event)"
            [isSelectedDefault]="true" [valueField]="valueTotalCustomer"
            textField="Tổng số lượng tài khoản"></component-statistics>

        <!-- Số lượng tài khoản hoạt động -->
        <component-statistics #accountActive (getSelected)="pushStatisticsToAllStatistics(filterCustomerActive, $event)"
            [valueField]="valueTotalCustomerActive" textField="Tài khoản đang hoạt động"
            color="#09880E"></component-statistics>

        <!-- Số lượng tài khoản vô hiệu hóa -->
        <component-statistics #accountActive
            (getSelected)="pushStatisticsToAllStatistics(filterCustomerDisable, $event)"
            [valueField]="valueTotalCustomerDisable" textField="Tài khoản bị vô hiệu hóa"
            color="#FF1D1D"></component-statistics>
    </div>

    <div class="form">
        <component-form [isDisabled]="true" text="Thông tin khách hàng">
            <div class="content-form content-form-customer-custom">
                <div class="block-1">
                    <!-- Mã khách hàng -->
                    <component-text-input placeholder="Nhập mã khách hàng..." #id [widthTextBox]="260"
                        [widthBlock]="370" label="Mã khách hàng"></component-text-input>

                    <!-- Tên khách hàng -->
                    <component-text-input placeholder="Nhập tên khách hàng..." #name [widthTextBox]="260"
                        [widthBlock]="370" label="Tên khách hàng"></component-text-input>

                    <!-- Email -->
                    <component-text-input placeholder="Nhập Email..." #email [widthTextBox]="260" [widthBlock]="370"
                        label="Email"></component-text-input>
                </div>

                <div class="block-2">
                    <!-- Ngày sinh -->
                    <component-datepicker #birthday [minDate]="minDate" [maxDate]="maxDate"
                        label="Ngày sinh"></component-datepicker>

                    <!-- Giới tính -->
                    <component-text-dropdown #gender [widthTextBox]="260" [widthBlock]="353" label="Giới tính"
                        [defaultItem]="defaultGender" [borderWidth]="1" borderColor="rgb(182, 182, 182)"
                        [heightTextBox]="30" [listItem]="listGender" textField="Gender"
                        valueField="Code"></component-text-dropdown>

                    <!-- Số điện thoại -->
                    <component-text-input placeholder="Nhập số điện thoại..." #phonenumber [widthTextBox]="260"
                        [widthBlock]="353" typeValue="number" label="Số điện thoại"></component-text-input>
                </div>

                <div class="block-3">
                    <div class="label-import-image">Ảnh đại diện</div>
                    <component-import-image #image></component-import-image>
                </div>
            </div>
        </component-form>
    </div>

    <div class="filter-search-container">
        <!-- Label danh sách khách hàng -->
        <div class="filter-search">
            <div class="label-filter-search">DANH SÁCH KHÁCH HÀNG</div>

            <!-- Thanh search danh sách khách hàng -->
            <component-search-bar #search (getValue)="setFilterSearch($event)"
                placeholder="Tìm kiếm theo mã và tên khách hàng..." [width]="500"></component-search-bar>
        </div>
    </div>

    <div class="grid-custom-container">
        <kendo-grid [data]="listCustomer" kendoGridSelectBy="Code" [selectedKeys]="selectedCodeCustomer"
            (selectionChange)="onSelectionChange($event)" [skip]="gridState.skip" [loading]="isLoading"
            [selectable]="{ mode: 'single' }" (pageChange)="onPageChange($event)" [pageSize]="gridState.take"
            [pageable]="{
                type: 'numeric',
                pageSizes: true,
            }">

            <!-- Checkbox -->
            <!-- <kendo-grid-checkbox-column [showSelectAll]="true" [width]="40"></kendo-grid-checkbox-column> -->

            <!-- Hình ảnh -->
            <kendo-grid-column title="Hình ảnh" [width]="70">
                <ng-template kendoGridCellTemplate let-item>
                    <div class="column-2">
                        <img *ngIf="item.ImageURL" [src]="item.ImageURL" alt="">
                        <img *ngIf="!item.ImageURL" [src]="imageNull.ImgUrl" alt="">
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- Mã và Tên khách hàng -->
            <kendo-grid-column title="Khách hàng" [width]="350">
                <ng-template kendoGridCellTemplate let-item>
                    <div class="column-3">
                        <!-- <div class="id">ID: {{item.IDCustomer}}</div> -->
                        <div class="name">Tên: {{item.Name}}</div>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- Giới tính và ngày sinh khách hàng -->
            <kendo-grid-column title="Ngày sinh" [width]="200">
                <ng-template kendoGridCellTemplate let-item>
                    <div class="column-4">
                        <div class="gender">{{item.Gender !== 0 && item.Gender !== 1 ? 'Không có' : checkGender(item.Gender)}}</div>
                        <div class="birthday">{{item.Birth ? formatDisplayDate(item.Birth) : 'Không có'}}</div>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- Số điện thoại -->
            <kendo-grid-column title="Số điện thoại" [width]="250">
                <ng-template kendoGridCellTemplate let-item>
                    <div class="column-5">
                        <div class="phone-number">{{item.PhoneNumber}}</div>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- Email -->
            <kendo-grid-column title="Email" [width]="200">
                <ng-template kendoGridCellTemplate let-item>
                    <div class="column-6">
                        <div class="email">{{item.Email}}</div>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- Trạng thái -->
            <kendo-grid-column title="Trạng thái" [width]="150">
                <ng-template kendoGridCellTemplate let-item>
                    <div class="column-7">
                        <div class="status" [style.color]="checkStatus(item.StatusAccount) | statusColor">
                            {{checkStatus(item.StatusAccount)}}</div>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- ToolBox -->
            <kendo-grid-column [sticky]="true" [resizable]="true" [width]="50">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="tool-box-container" (click)="onClickToolBox(dataItem, $event)">
                        <component-button class="button-tool-box" [width]="30" [height]="30" [size]="15" color="#000"
                            bgColor="#fff" classIconFontAwesome="fa-ellipsis" text=""></component-button>
                        <div class="list-action" *ngIf="codeCustomerSelected === dataItem.Code">
                            <component-dropdown-action [listItem]="getListChangeStatus(dataItem.StatusAccount)"
                                (sendValue)="updateStatusCustomer(dataItem.CodeAccount, $event)"></component-dropdown-action>
                        </div>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- Paging -->
            <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                <kendo-pager-page-sizes pagerGrid [pageSizes]="listPageSize"></kendo-pager-page-sizes>
                <div class="pager-buttons">
                    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                    <kendo-pager-numeric-buttons [buttonCount]="3"></kendo-pager-numeric-buttons>
                    <kendo-pager-next-buttons></kendo-pager-next-buttons>
                </div>
            </ng-template>
        </kendo-grid>
    </div>
</div>