<div class="admin009" *ngIf="checkPermission()">

    <div class="header-statistics">
        <div class="block-1">
            <div class="label-filter">LỌC DỮ LIỆU</div>

            <!-- Số lượng sản phẩm đang kinh doanh -->
            <component-statistics #productActive [isSelectedDefault]="true"
                (getSelected)="pushStatisticsToAllStatistics(filterProductActive, $event)"
                [valueField]="valueProductStatusActive" textField="Sản phẩm đang kinh doanh"
                color="#09880E"></component-statistics>

            <!-- Số lượng sản phẩm ngưng kinh doanh -->
            <component-statistics #productDisable
                (getSelected)="pushStatisticsToAllStatistics(filterProductDisable, $event)"
                [valueField]="valueProductStatusDisable" textField=" Sản phẩm ngưng kinh doanh"
                color="#FF1D1D"></component-statistics>

            <!-- Số lượng sản phẩm Unisex -->
            <component-statistics #productUnisex [isSelectedDefault]="true"
                (getSelected)="pushStatisticsToAllStatistics(filterGenderUnisex, $event)"
                [valueField]="valueProductUnisex" textField="Sản phẩm Unisex"></component-statistics>

            <!-- Số lượng sản phẩm cho Nam -->
            <component-statistics #productMale (getSelected)="pushStatisticsToAllStatistics(filterGenderMale, $event)"
                [valueField]="valueProductMale" textField="Sản phẩm cho Nam" color="#B66D01"></component-statistics>

            <!-- Số lượng sản phẩm cho Nữ -->
            <component-statistics #productFemale
                (getSelected)="pushStatisticsToAllStatistics(filterGenderFeMale, $event)"
                [valueField]="valueProductFemale" textField="Sản phẩm cho Nữ" color="#C6009A"></component-statistics>
        </div>

        <!-- Button thêm sản phẩm -->
        <component-button class="button-add" [borderWidth]="2" color="#09880E" borderColor="#09880E" borderStyle="solid"
            [value]="0" (sendValue)="goToDetail($event, 0)" [height]="36" classIconFontAwesome="fa-plus" bgColor="#fff"
            text="Thêm mới"></component-button>
    </div>

    <div class="header-filter-search">
        <!-- Xóa bộ lọc -->
        <div class="label-delete-filter" (click)="resetFilter()">Xóa bộ lọc</div>

        <!-- Thanh tìm kiếm -->
        <component-search-bar #search (getValue)="setFilterSearch($event)" [width]="510"
            placeholder="Tìm kiếm theo mã, tên sản phẩm..."></component-search-bar>

        <!-- Dropdown lọc khoảng giá tiền -->
        <component-text-dropdown #rangeprice (getValue)="setFilterPrice($event)" [widthBlock]="160" [widthTextBox]="160"
            textField="RangePrice" valueField="Code" [listItem]="listRangePrice" [defaultItem]="defaultPrice" />

        <!-- Dropdown lọc loại sản phẩm -->
        <component-text-dropdown #producttype
            (getValue)="setFilterProperty(filterProductType, 'CodeProductType', 'eq', 'Code', $event)"
            [widthBlock]="160" [widthTextBox]="160" textField="Name" valueField="Code" [listItem]="listProductType"
            [defaultItem]="defaultProductType" />

        <!-- Dropdown lọc loại theo thương hiệu -->
        <component-text-dropdown #brand (getValue)="setFilterProperty(filterBrand, 'CodeBrand', 'eq', 'Code', $event)"
            [widthBlock]="160" [widthTextBox]="160" textField="Name" valueField="Code" [listItem]="listBrand"
            [defaultItem]="defaultBrand" />

        <!-- Dropdown sắp xếp -->
        <component-text-dropdown #sort (getValue)="setSort($event)" [widthBlock]="170" [widthTextBox]="170"
            textField="Status" valueField="Code" [listItem]="listSort" [defaultItem]="defaultSort" />
    </div>

    <div class="label-list-product">Danh sách sản phẩm</div>

    <div class="grid-custom-container">
        <kendo-grid [skip]="gridState.skip" [loading]="isLoading" (pageChange)="onPageChange($event)"
            [pageSize]="gridState.take" [pageable]="{
                type: 'numeric',
                pageSizes: true,
            }" [data]="listProduct" [selectable]="{ checkboxOnly: true }">

            <!-- Checkbox -->
            <!-- <kendo-grid-checkbox-column [showSelectAll]="true" [width]="40"></kendo-grid-checkbox-column> -->

            <!-- Hình ảnh -->
            <kendo-grid-column title="Hình ảnh" [width]="100">
                <ng-template kendoGridCellTemplate let-item>
                    <div class="column-2">
                        <img [src]="item.ThumbnailImg" alt="">
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- Tên và Mã sản phẩm -->
            <kendo-grid-column title="Sản phẩm" [width]="300">
                <ng-template kendoGridCellTemplate let-item>
                    <div class="column-3">
                        <div class="idproduct">ID: {{item.IdProduct}}</div>
                        <div class="name" [title]="item.Name">Sản phẩm: {{item.Name}}</div>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- Loại sản phẩm -->
            <kendo-grid-column title="Loại sản phẩm" [width]="160">
                <ng-template kendoGridCellTemplate let-item>
                    <div class="column-4">{{item.ProductType}}</div>
                </ng-template>
            </kendo-grid-column>

            <!-- Thương hiệu -->
            <kendo-grid-column title="Thương hiệu" [width]="170">
                <ng-template kendoGridCellTemplate let-item>
                    <div class="column-5">{{item.BrandName}}</div>
                </ng-template>
            </kendo-grid-column>

            <!-- Số lượng còn lại -->
            <kendo-grid-column title="Số lượng còn lại" [width]="150">
                <ng-template kendoGridCellTemplate let-item>
                    <div class="column-6">{{item.Stock}}</div>
                </ng-template>
            </kendo-grid-column>

            <!-- Đã bán -->
            <kendo-grid-column title="Đã bán" [width]="100">
                <ng-template kendoGridCellTemplate let-item>
                    <div class="column-7">{{item.Sold}}</div>
                </ng-template>
            </kendo-grid-column>

            <!-- Giới tính -->
            <kendo-grid-column title="Giới tính" [width]="100">
                <ng-template kendoGridCellTemplate let-item>
                    <div class="column-8">{{checkGender(item.Gender)}}</div>
                </ng-template>
            </kendo-grid-column>

            <!-- Đơn giá -->
            <kendo-grid-column title="Đơn giá" [width]="140">
                <ng-template kendoGridCellTemplate let-item>
                    <div class="column-9">Giá gốc: {{formatCurrency(item.Price)}}</div>
                    <ng-container *ngIf="item.Discount">
                        <div class="column-9 column-9-discount">Giảm giá: {{item.Discount}}%</div>
                    </ng-container>
                    <ng-container *ngIf="item.Discount">
                        <div class="column-9">Giảm giá: {{formatCurrency(item.PriceAfterDiscount)}}</div>
                    </ng-container>
                </ng-template>
            </kendo-grid-column>

            <!-- Trạng thái -->
            <kendo-grid-column title="Trạng thái" [width]="150">
                <ng-template kendoGridCellTemplate let-item>
                    <div class="column-10" [style.color]="checkStatusProduct(item.Status) | statusColor">
                        {{checkStatusProduct(item.Status)}}
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- ToolBox -->
            <kendo-grid-column [sticky]="true" [resizable]="true" [width]="50">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="tool-box-container" (click)="onClickToolBox(dataItem, $event)">
                        <component-button class="button-tool-box" [width]="30" [height]="30" [size]="15" color="#000"
                            bgColor="#fff" classIconFontAwesome="fa-ellipsis" text=""></component-button>
                        <div class="list-action" *ngIf="codeProductSelected === dataItem.Code">
                            <component-dropdown-action (sendValue)="updateStatusProduct(dataItem, $event)"
                                [listItem]="findItemActionList(dataItem.Status)"></component-dropdown-action>
                        </div>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- Paging -->
            <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                <kendo-pager-page-sizes pagerGrid [pageSizes]="listPageSize"></kendo-pager-page-sizes>
                <div class="pager-buttons">
                    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                    <kendo-pager-numeric-buttons [buttonCount]="3"></kendo-pager-numeric-buttons>
                    <kendo-pager-next-buttons></kendo-pager-next-buttons>
                </div>
            </ng-template>

        </kendo-grid>
    </div>

</div>