<div class="container" *ngIf="checkAvailabelAccount()">
    <div class="sidebar">
        <app-sidebar-admin #sidebar></app-sidebar-admin>
    </div>
    <div class="content">
        <div class="header">
            <app-header-admin></app-header-admin>
        </div>
        <div class="content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>