<app-loading [isLoading]="isLoading"></app-loading>

<div class="main">
    <div class="title">
        {{"your bag" | uppercase}}
    </div>
    <div class="line-area">
        <div class="under-line"></div>
    </div>
    <div class="content-area">
        <div class="cart-item">
            <div class="content">
                <div class="header">
                    <div class="title-header-item">
                        For you
                    </div>
                    <div class="text-item-have">
                        {{cart?.ListProductInCart.length}} Your Style
                    </div>
                </div>
                <div class="under-line"></div>
                <div class="item-area">
                    <!-- <div class="loading">
                        <app-loading [isLoading]="true"></app-loading>
                    </div> -->
                    <div class="item" *ngFor="let item of cart?.ListProductInCart">
                        <div class="infor">
                            <label class="custom-checkbox">
                                <input 
    
                                    (change)="handleCheckItem(item)" 
                                    name="dummy" 
                                    type="checkbox"
                                >
                                <span class="checkmark"></span>
                              </label>
                              <div *ngFor="let img of item.Product.ListOfImage">
                                <div  class="image" *ngIf="img.IsThumbnail == true">
                                    <img src="{{img.ImgUrl}}" alt="">
                                </div>
                            </div>

                            <div class="info-area">
                                <div class="name">
                                    {{item.Product.Name}}
                                </div>
                                <div class="color">
                                    <div class="title-item">
                                        Color
                                    </div>
                                    <div class="center-line">
                                        -
                                    </div>
                                    <div class="text">
                                        {{item.Product.Color}}
                                    </div>
                               
                                </div>
                                <div class="size">
                                    <div class="title-item">
                                        Size
                                    </div>
                                    <div class="center-line">-</div>
                                    <div class="text">
                                        {{item.SizeSelected.Size}}
                                    </div>
                                </div>  
                               
                            </div>
                        </div>
                        
                        <div class="price-area">
                            <div  class="final-price">
                                {{item.Product.PriceAfterDiscount | number: '1.0-0'}} vnd
                            </div>
                            <div *ngIf="item.Product.PriceAfterDiscount < item.Product.Price" class="origin-price">
                                {{item.Product.Price | number: '1.0-0'}} vnd
                            </div>
                        </div>

                        <div class="quantity-area">
                            <i (click)="handleClickFunction(item, 'Update')" class="fa-solid fa-minus"></i>
                            <div class="quantity">
                                <input class="input" type="text" [value]="item.Quantity">
                            </div>
                            <i (click)="handleClickFunction(item, 'Add')" class="fa-solid fa-plus"></i>
                        </div>

                        <div class="total-price-area">
                            <div class="title-item">
                                Total:
                            </div>
                            <div>
                                {{item.TotalPriceOfProduct | number: '1.0-0'}} vnd
                            </div>
                        </div>
                        <div (click)="handleClickFunction(item, 'Delete')" class="delete-area">
                            <i class="icon fa-solid fa-trash"></i>
                        </div>
                  
                    </div>

                </div>
            </div>
        </div>
        <div class="cart-summary">
            <div class="content">
                <div class="summary">
                    <div class="text">
                        Summary
                    </div>
                </div>
                <div class="line-area">
                    <div class="under-line"></div>
                </div>
                <div class="content-detail">
                    <div class="title">
                        {{"price detail" | uppercase}}
                    </div>
                    <div class="content-child">
                        <div class="item">
                            <div class="title">
                                Total items
                            </div>
                            <div class="text">
                                {{totalItem}}
                            </div>
                        </div>
                        <div class="item">
                            <div class="title">
                                SubTotal
                            </div>
                            <div class="text">
                                {{subTotalItem}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="line-area">
                    <div class="under-line"></div>
                </div>
                <div class="go-payment-area">
                    <div class="total-price-area">
                        <div class="title-total-price">
                            Total price
                        </div>
                        <div class="text-total-price">
                            {{totalPrice | number: '1.0-0'}} vnd
                        </div>
                    </div>
                    <div (click)="handleCheckout()" class="btn-checkout">
                        Check out
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
