<div class="component-datepicker" [style.width.px]="widthBlock">
    <ng-container *ngIf="label">
        <span>{{label}}</span>
    </ng-container>
    <kendo-datepicker 
        #datePicker
        calendarType="classic"
        (valueChange)="onChange($event)" 
        format="dd/MM/yyyy" 
        formatPlaceholder="formatPattern" 
        datepicker-kendo 
        [value]="defaultDate"
        [min]="minDate"
        [max]="maxDate"
        />
</div>