<div class="dropdown" [ngStyle]="{
    'border-radius': rounded,
}">
    <div *ngFor="let item of listItem">
      <component-button 
      (sendValue)="onClickItemDropdown($event)"
      [classIconFontAwesome]="item.Icon" 
      [text]="item.Status" 
      [value]="item.Code" 
      [rounded]="0" 
      [bgColor]="'#E4E4E4'" 
      [color]="'#000000'" 
      [fontWeight]="0" 
      [justify]="'start'"></component-button>
    </div>
  </div>

  
