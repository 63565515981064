<div class="header">
    <div class="head">
        <div class="logo">
            <img class="img" src="../../../../../assets/logo/logoHyperRMB2.png" alt="">
        </div>
        <div class="content-center">
            <div  *ngFor="let item of dataModuleHeader">
                <div [routerLink]="item.route" (click)="handleSelectActionCenter(item.id)"  class="item">
                    <div>{{item.text}}</div>
                </div>
    
            </div>
        </div>
        <div class="content-right">
            <div class="search-area">
                <div class="icon">
                    <i class="fa-solid fa-magnifying-glass"></i>
                </div>
               
            </div>
            <div (click)="navigateProfile()" class="profile">
                <i class="fa-regular fa-user"></i>
    
            </div>
            <div routerLink="cart" class="cart">
                <i class="icon fa-solid fa-cart-shopping"></i>
                <div *ngIf="totalItemCart > 0" class="noitifi">{{totalItemCart}}</div>
            </div>
     
        </div>
    </div>
</div>
