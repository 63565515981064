<app-loading [isLoading]="isLoading"></app-loading>

<div class="main">
    <div class="content">
        <div class="info-area">
            <div (click)="log()" class="title">
                Payment 
            </div>
            <div class="form-area">
                <div class="textbox">
                    <div class="title-item">
                        <div class="title">Order phone number <span [ngStyle]="{'color': 'red'}">*</span></div>
                        <div *ngIf="codeCustomer" class="checkbox-order">
                            <div class="title">Buy for others</div>
                            <input [(ngModel)]="isBuyOther" type="checkbox" kendoCheckBox>
                        </div>
                    </div>
                    <kendo-textbox [disabled]="!!codeCustomer" [(ngModel)]="numberPhone" class="input" placeholder="Your phone"></kendo-textbox>
                </div> 
                <div *ngIf="isBuyOther" class="textbox">
                    <div class="title">Recipient's phone <span [ngStyle]="{'color': 'red'}">*</span></div>
                    <kendo-textbox [(ngModel)]="recipientPhone" class="input" placeholder="Your phone"></kendo-textbox>
                </div> 
                <div class="textbox">
                    <div class="title">Recipient's name <span [ngStyle]="{'color': 'red'}">*</span></div>
                    <kendo-textbox [(ngModel)]="name" class="input" placeholder="Your name"></kendo-textbox>
                </div> 
            
                <div class="textbox">
                    <div class="title">Province <span [ngStyle]="{'color': 'red'}">*</span></div>
                    <kendo-dropdownlist  
                        [loading]="isLoadingProvince"
                        [data]="dataProvineFilter"
                        textField="province_name"
                        valueField="province_name"
                        [filterable]="true"
                        [(ngModel)]="provinceSelected"
                        (valueChange)="handleChangeProvince()"
                        [defaultItem]="defaultValueProvince"
                        (filterChange)="hanldeFilterProvine($event)"
                       ></kendo-dropdownlist>
                </div> 
                <div class="textbox">
                    <div class="title">District <span [ngStyle]="{'color': 'red'}">*</span></div>
                    <kendo-dropdownlist  
                        [loading]="isLoadingDistrict"
                        [data]="dataDistrictFilter"
                        textField="district_name"
                        valueField="district_name"
                        [filterable]="true"
                        [disabled]="isDisableDistrict"
                        [(ngModel)]="districtSelected"
                        [defaultItem]="defaulValueDistrict"
                        (valueChange)="handleChangeDistrict()"
                        (filterChange)="hanldeFilterDistrict($event)"
                       ></kendo-dropdownlist>
                </div> 
                <div class="textbox">
                    <div class="title">Ward <span [ngStyle]="{'color': 'red'}">*</span></div>
                    <kendo-dropdownlist  
                        [loading]="isLoadingWard"
                        [data]="dataWardFilter"
                        textField="ward_name"
                        valueField="ward_name"
                        [filterable]="true"
                        [disabled]="isDisableWard"
                        [defaultItem]="defaultValueWard"
                        [(ngModel)]="wardSelected"
                        (valueChange)="handleChangeWard()"
                        (filterChange)="hanldeFilterWard($event)"
                       ></kendo-dropdownlist>
                </div> 
                <div class="textbox">
                    <div class="title">Road <span [ngStyle]="{'color': 'red'}">*</span></div>
                    <kendo-textbox [(ngModel)]="road" [disabled]="isDisableSpecific" class="input" placeholder="Your specific address"></kendo-textbox>
                </div> 

                <div class="textbox">
                    <div class="title">Specific address <span [ngStyle]="{'color': 'red'}">*</span></div>
                    <kendo-textbox [(ngModel)]="specific" [disabled]="isDisableSpecific" class="input" placeholder="Your specific address"></kendo-textbox>
                </div> 

                <div class="payment-method-select-area">
                    <div class="title title-method">
                        Payment method <span [ngStyle]="{'color': 'red'}">*</span>
                    </div>
                    <div *ngIf="codeCustomer != 0" class="area-button-method">
                        <div (click)="handleSelectedPaymentMethod(p)" *ngFor="let p of listPaymentMethodUser" [ngClass]="paymenMethodSelected?.id == p.id ? 'method-selected' : 'method'">
                            <div>
                                <div class="area">
                                    <i class="fa-solid {{p.icon}}"></i>
                                    <div>{{p.text}}</div>
                                </div>
                                <div class="radio-area">
                                    <div class="radio">

                                    </div>
                                </div>
                               
                            </div>
                           
                        </div>
                    </div>
                    <div *ngIf="!codeCustomer" class="area-button-method">
                        <div (click)="handleSelectedPaymentMethod(p)" *ngFor="let p of listPaymentMethodGuess" [ngClass]="paymenMethodSelected?.id == p.id ? 'method-selected' : 'method'">
                            <div>
                                <div class="area">
                                    <i class="fa-solid {{p.icon}}"></i>
                                    <div>{{p.text}}</div>
                                </div>
                                <div class="radio-area">
                                    <div class="radio">

                                    </div>
                                </div>
                               
                            </div>
                           
                        </div>
                       
                    </div>
                </div>

                <div class="textbox coupon-area">
                    <div class="title title-method">
                        Coupon
                    </div>
                    <kendo-textbox (blur)="APIApplyCoupon(applyCouponRequest)" [(ngModel)]="applyCouponRequest.IdCoupon" class="input" placeholder="Type your coupon"></kendo-textbox>
                    <div [ngClass]="appliedCoupon.IdCoupon || errorCoupon ? 'info-coupon' : ''" >
                        <div  *ngIf="appliedCoupon.IdCoupon" class="info">
                            <div class="nav">
                                Coupon: <span>{{appliedCoupon.IdCoupon}}</span>
                            </div>
                            <div class="nav">
                                Type coupon: <span>{{getStringTypeCoupon(appliedCoupon.CouponType)}}</span>
                            </div>
                            <div>
                                <div *ngIf="appliedCoupon.CouponType == 0" class="nav">
                                    Value discount: <span>{{appliedCoupon.PercentDiscount}}</span>%
                                </div>
                                <div *ngIf="appliedCoupon.CouponType == 1" class="nav">
                                    Value discount: <span>{{appliedCoupon.DirectDiscount | number: '1.0-0'}}vnd</span>
                                </div>
                            </div>
                            <div class="apply">
                                Applied!
                            </div>
                        </div>
                        <div *ngIf="errorCoupon" class="error">
                            {{errorCoupon}} 
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="price-area">
            <div class="price">
                <div class="title">
                    Order summary
                </div>
                <div class="price-description">
                    <div class="sub-total">
                        <div class="title-child">
                            Subtotal
                        </div>
                        <div class="price-child">
                            {{priceSubTotal | number: '1.0-0'}} vnd
                        </div>
                    </div>
                    <div class="sub-total">
                        <div class="title-child">
                            Delivery
                        </div>
                        <div class="price-child">
                            {{priceDelivery | number: '1.0-0'}} vnd
                        </div>
                    </div>
                    <div class="sub-total">
                        <div class="title-child">
                            Coupon
                        </div>
                        <div class="price-coupon">
                            <span *ngIf="priceCoupon > 0">-</span> {{priceCoupon | number: '1.0-0'}} vnd
                        </div>
                    </div>
                </div>
    
                <div class="price-total-area">
                    <div class="under-line"></div>
                    <div class="total-price">
                        <div class="title-child">
                            Total price
                        </div>
                        <div class="price-child">
                            {{totalPrice | number: '1.0-0'}} vnd
                        </div>
                    </div>
                    <div class="under-line"></div>
                </div>

                <div (click)="handlePayment()" class="btn-payment">
                    Payment
                </div>
            </div>

            <div class="product">
                <div class="title">
                    Product
                </div>
                <div class="show-product">
                    <div *ngFor="let product of listProductPayment" >
                        <div class="item">
                            <div *ngFor="let img of product.Product.ListOfImage">
                                <div  class="image" *ngIf="img.IsThumbnail == true">
                                    <img src="{{img.ImgUrl}}" alt="">
                                </div>
                            </div>
                            <div class="area-info">
                                <div class="info">
                                    <div class="name">
                                        {{product.Product.Name}}
                                    </div>
                                    <div class="size">
                                        size: {{product.SizeSelected.Size}}
                                    </div>
                                    <div class="quantity">
                                        x{{product.Quantity}}
                                    </div>
                                </div>
                                <div (click)="handleDeleteProduct(product)" class="delete"> 
                                    <i class="fa-solid fa-trash"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>