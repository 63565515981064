<app-loading class="loading" [isLoading]="isLoading"></app-loading>
<div class="main">
    <div class="content">
        <div class="sidebar">
            <div class="content-sidebar">
                    <!-- <div class="filter filter-gender">
                        <div class="title">
                            Hot
                        </div>
                      
                            <div class="gender">
                                <label class="custom-checkbox">
                                    <input [(ngModel)]="isSale" name="dummy" type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                                <div>
                                    Sale
                                </div>
                            </div>
             
                    </div> -->
                    <div class="filter filter-type">
                        <div class="title">
                            Category
                        </div>
                        <div *ngFor="let type of listProductType; let i = index">
                            <div *ngIf="i < categoryCount" class="type">
                                <label class="custom-checkbox">
                                    <input [checked]="isCheckedInputCategory(type)" (change)="handleSelectedCatelogy(type)" name="dummy" type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                                <div [ngClass]="type.Code == typeSelected ? 'type-selected' : 'item-type'">
                                    {{type.Name}}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isShowMoreCategory == true" (click)="handleShowMoreCategory()" class="show-more hide">Hide category</div>
                        <div *ngIf="isShowMoreCategory == false" (click)="handleShowMoreCategory()" class="show-more show">Show more category</div>
                    </div>
        
                    <div class="filter filter-gender">
                        <div class="title">
                            Gender
                        </div>
                        <div *ngFor="let gen of listGender">
                            <div class="gender">
                                <label class="custom-checkbox">
                                    <input [(ngModel)]="gen.checked" name="dummy" type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                                <div>
                                    {{gen.text}}
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <div class="filter filter-price">
                        <div class="title">
                            Price
                        </div>
                        <div>
                            <kendo-rangeslider
                                tickPlacement="none"
                                [min]="minRange"
                                [max]="maxRange"
                                [(ngModel)]="valueRange"
                                (valueChange)="handleValuePriceChange()"
                            ></kendo-rangeslider>
                            <div>
                                {{minPrice | number: '1.0-0'}} / {{maxPrice | number: '1.0-0'}}
                            </div>
                        </div>
                    </div>
        
        
                    <div class="filter filter-brand">
                        <div class="title">
                            Brand
                        </div>
                        <div *ngFor="let brand of listBrand; let i = index">
                            
                            <div *ngIf="i < brandCount" class="brand">
                                <label class="custom-checkbox">
                                    <input [checked]="handleCheckBrand(brand.Code)" (change)="handleSelectedBrand(brand)" name="dummy" type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                                <div>
                                    {{brand.Name}}
                                </div>
                            </div>
                        
                        </div>
                        <div *ngIf="isShowMoreBrand == true" (click)="handleShowMoreBrand()" class="show-more hide">Hide brand</div>
                        <div *ngIf="isShowMoreBrand == false" (click)="handleShowMoreBrand()" class="show-more show">Show more brand</div>
                    </div>
        
                    <div (click)="handleApplyFilter()" class="btn-apply"> 
                        {{"apply" | uppercase}}
                    </div>
                    
                    
                </div>
            </div>
        

        <div class="right-content">
            <div class="sort">
                <div class="check">
                </div>
                <div class="search">
                    <div class="input">
                        <input [(ngModel)]="keySearch" placeholder="Search name you want..."/>
                        <i (click)="handleApplyFilter()" class="fa-solid fa-magnifying-glass"></i>
                    </div>
                </div>
              
            </div>
            <div *ngIf="ListProduct.length > 0" class="grid-show">
                <div class="product-list">
                    <div class="item" *ngFor="let product of ListProduct">
                        <app-ecom-product-card [product]="product"></app-ecom-product-card>
                    </div>
                </div>
                <div *ngIf="isShowLoadMore" class="load-area">
                    <div (click)="handleLoadMore()" class="btn-load-more">
                        {{'load more' | uppercase}}
                    </div>
                </div>
            </div>

            <div *ngIf="ListProduct.length <= 0" class="no-data">
                No data in here 😭
            </div>
            
        </div>
        
    </div>
    
</div>
