import { Component } from '@angular/core';

@Component({
  selector: 'app-admin007-manage-schedule',
  templateUrl: './admin007-manage-schedule.component.html',
  styleUrls: ['./admin007-manage-schedule.component.scss']
})
export class Admin007ManageScheduleComponent {

}
